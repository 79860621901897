import React ,{useRef, useState} from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Formik, Field } from "formik";
import settings from "../../settings";
import * as Yup from "yup";
import Spinner from "../components/spinner";
import ReCAPTCHA from "react-google-recaptcha";
import AXIOS from "../../axios-config";
import cogoToast from "cogo-toast";
import classNames from "classnames";
import ImageBackground from "../components/imagebackground";


// images
const topbanner = `${settings.IMAGES_BASE_URL}/images/LAASContest/bmw-la-show-banner.jpg`;
const laLogo = `${settings.IMAGES_BASE_URL}/images/LAASContest/la-auto-show.jpg`;
const today = new Date();
const laasContestDate =
  today >= new Date(2024,9,25) && 
  today < new Date("2024-12-13T17:00:00-07:00")

const LAASContest = () => {
  const contentRef = useRef(null)
  const captchaRef =  useRef();
  const [captchaToken, setCaptchaToken] = useState("");
  const [isLoading,setIsLoading] = useState(false);
  
  const validationSchema = Yup.object().shape({
    fullname: Yup.string()
      .required("Please enter your full name")
      .min(1, "Must have a character")
      .max(255, "Must be shorter than 255"),
    email: Yup.string()
      .email()
      .required("Please enter your email address")
      .matches(
        /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        "Your email address is incorrect"
      ),
    zipcode: Yup.string()
      .required("Please enter your zip")
      .min(1, "Must have a character")
      .max(255, "Must be shorter than 255"),
    phone: Yup.string()
      .required('Please enter your phone number')
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        'Please enter a valid phone number'
      )
  });

  const errorHandling = (setSubmitting) => {
    captchaRef.current.reset();
    setCaptchaToken("");
    setIsLoading(false);
    setSubmitting(false)
    window.grecaptcha.reset();
  }


  const handleSubmit = async (values, { setSubmitting, resetForm}) => {
    setIsLoading(true) 
    setSubmitting(true);
    const headers = {
      'captchaToken': captchaToken
    };

    if (!captchaToken) {
      cogoToast.error("You must verify the capctcha", {
        position: "top-center"
      });
      errorHandling(setSubmitting);
      return
    }
    AXIOS.post("/api/laas-contest-form", values, {headers})
      .then(() => {
        resetForm();
        errorHandling(setSubmitting);
        cogoToast.success("Your form has been submitted successfully", {
          position: "top-center",
        });
      })
      .catch((err) => {
        errorHandling(setSubmitting);
        cogoToast.error("Your form wasn't submitted", {
          position: "top-center",
        })
      }).finally(() => {
        errorHandling(setSubmitting);
      });
  };

  const getOfficialRules = () => {

    if (laasContestDate) {
      return (
        <section className="performance-center-contest-rules">
          <p>
            <span className="bold">OFFICIAL RULES</span><br/>
            <span className="bold">
              BMW PERFORMANCE CENTER LAAS ONE DAY M SCHOOL GIVEAWAY
            </span>
            <br />
            <br />
          </p>
          <p>
            NO PURCHASE IS NECESSARY TO ENTER OR WIN. A PURCHASE WILL NOT INCREASE YOUR CHANCES OF WINNING. ONE, FREE, ONE DAY M SCHOOL Seat at the BMW Performance Center in Thermal, California Giveaway (the “Promotion”) begins at 8:00 a.m. Pacific Time (“PT”) on October 25, 2024 and ends at 5:00 p.m. PT on December 13, 2024 (“Promotion Period”).
            <br />
            <br />
            ELIGIBILITY: Promotion OPEN ONLY TO LEGAL RESIDENTS OF ALL 50 STATES WITH A VALID U.S. DRIVERS LICENSE. Employees, contract workers and lease employees (and their immediate families (parent, child, spouse or sibling and their respective spouses, regardless of where they reside) and those living in their same households, whether or not related) of the BMW of North America, LLC (“Sponsor”), and their respective parents, affiliates, subsidiaries, and advertising and promotion agencies are not eligible to enter or win. By participating, entrants agree to be bound by these Official Rules and the decisions of the judges and/or Sponsor, which are binding and final on matters relating to this promotion. Promotion is subject to all applicable federal, state and local laws.
            <br />
            <br />
            ENTRY PERIODS AND HOW TO ENTER: To register, during the Promotion Period, you must click the link in contest announcements provided via emails or social posts from the LA Auto Show and BMW Performance Center West driving to our website entry form; fill out form with your name, email address, phone number and zip code to be entered to win. Entries generated by script, macro or other automated means and entries by any means which subvert the entry process are void. Limit of one (1) entry per person. Entries received from any person or account in-excess of the above-stated limitation will be void. All entries become the sole property of the Sponsor and will not be acknowledged or returned. Opt-in opportunities may be available but are not required and will not increase or decrease your chances of winning. Opting in to receive email and other electronic communications from BMW is not a pre-condition to contest entry.
            <br />
            <br />
            DRAWING: One winner will be selected on Monday, December 16, 2024. The winner will be selected by a random drawing among all eligible entries. Actual odds of winning depend upon the number of eligible entries received. Potential winner will be notified by email on or around December 16, 2024. If potential winner declines the prize or a potential winner is not in compliance with these rules, the applicable prize will be forfeited and, at Sponsor’s discretion, an alternate winner selected by a random drawing among all remaining eligible entries. The potential winner may be required to execute and return an affidavit of eligibility, a liability release, and a publicity release on or about December 16, 2024. If such documents are not returned within the specified time period, prize notification is returned as undeliverable, or the potential winner will not be in compliance with these rules, the prize will be forfeited and, at Sponsor’s discretion, a runner-up may be notified. The prize will be awarded after receipt of the documentation and verification of eligibility. The sponsor’s decisions are final and binding on all matters relating to this Contest.
            <br />
            <br />
            PRIZE: One prize will be awarded to one winner for the California location during the contest period. Approximate value of each prize is $1,750 + tax. The prize shall consist of the following, good for the selected winner: 1 Free One Day M School Seat at the BMW Performance Center in Thermal. Travel expenses not included. The winner will be responsible for all travel expenses. Prizes are awarded “as is” with no warranty or guarantee, either express or implied by the Sponsor. Winner may not substitute, assign or transfer prize or redeem prize for cash, but Sponsor reserves the right, at its sole discretion, to substitute prize (or portion thereof) with one of comparable or greater value. Winner is responsible for all applicable federal, state, and local taxes, if any, as well as any other costs and expenses associated with prize acceptance and use not specified herein as being provided. All prize details are at Sponsor’s sole discretion and subject to change.
            <br />
            <br />
            GENERAL CONDITIONS: Released Parties (as defined below) are not responsible for lost, late, incomplete, inaccurate, stolen, 
            misdirected, undelivered, garbled, illegible or postage-due mail, entries or email; or for lost, 
            interrupted or unavailable network, server Internet Service Provider (ISP), website, or other connections, 
            availability or accessibility or miscommunications or failed computer, satellite, telephone or cable 
            transmissions, lines, or technical failure or jumbled, scrambled, delayed, or misdirected transmissions or 
            computer hardware or software malfunctions, failures or difficulties, or other errors or difficulties of 
            any kind whether human, mechanical, electronic, computer, network, typographical, printing or otherwise 
            relating to or in connection with the promotion, including, without limitation, errors or difficulties 
            which may occur in connection with the administration of the promotion, the processing of entries, the 
            announcement of the prizes or in any promotion-related materials. Released Parties are also not 
            responsible for any incorrect or inaccurate information, whether caused by site users, (seem accurate) 
            tampering, hacking, or by any equipment or programming associated with or utilized in the promotion. 
            Released Parties are not responsible for injury or damage to participants’ or to any other person’s 
            computer related to or resulting from participating in this promotion or downloading materials from or 
            use of the website. Persons who tamper with or abuse any aspect of the promotion or website or who are 
            in violation of these Official Rules, as solely determined by Sponsor, will be disqualified and all 
            associated entries will be void. Should any portion of the promotion be, in the Sponsor’s sole opinion, 
            compromised by a virus, worms, bugs, non-authorized human intervention or other causes which, in the 
            sole opinion of the Sponsor, corrupt or impair the administration, security, fairness or proper play, 
            or submission of entries, Sponsor reserves the right at its sole discretion to suspend, modify or 
            terminate the promotion and, if terminated, at its discretion, select the potential winners from all 
            eligible, non-suspect entries received prior to action taken or as otherwise deemed fair and appropriate 
            by Sponsor. Entrants, by participating, agree that Sponsor and their respective parents, affiliates, 
            subsidiaries, and advertising and promotion agencies and all of their respective officers, directors, 
            employees, representatives and agents (collectively, “Released Parties”) will have no liability 
            whatsoever for, and shall be held harmless by entrants against, any liability, for any injuries, losses 
            or damages of any kind, including death, to persons, or property resulting in whole or in part, directly 
            or indirectly, from acceptance, possession, misuse or use of any prize or participation in this promotion. 
            Except where legally prohibited, entry into this Contest and/or winner’s acceptance of prize constitutes 
            permission for Sponsor and its designees to use his/her name, address (city and state), photograph, voice 
            and/or another likeness, biographical information and prize information for advertising, trade and 
            promotional purposes without further compensation, in all media now known or hereafter discovered, 
            worldwide in perpetuity, without notice or review or approval. In the event of a dispute regarding 
            entries received from multiple users having the same Instagram account, the authorized subscriber of the 
            Instagram account at the time of entry will be deemed to be the entrant and must comply with these rules. 
            An authorized account subscriber is the natural person who is assigned the Instagram account by Instagram, 
            or other organization responsible for assigning Instagram accounts.
            <br />
            <br />
            SPONSOR: BMW of North America, LLC 86-050 Jasper Lane, Thermal, CA 92274
          </p>
        </section>
      );
    }
  }

  return (
    <Layout>
      <SEO title="BMW Performance Driving School | Press" description="Download our Press Kit with BMW Performance Center graphics, information and articles about us. Media inquiries? You're in the right place." />
      <div>
        <ImageBackground
          imageDesktop={topbanner}
          imageMobile={topbanner}
          imageAlt="LAASContest"
          hideArrow
        />
        {laasContestDate && (
        <div className="container">
          <div className="laas-contest">
            <div className="contest-form">
              <h4>REGISTER TO WIN A ONE-DAY M SCHOOL <br/> AT THE BMW PERFORMANCE CENTER WEST IN THERMAL, CA</h4>
              <img effect="blur" data-src={laLogo} alt="laLogo" className="lazyload"/>
              <div className="contest-form-inner">
              <p>
                As an Official Partner, we’d like to thank you for attending the 2024 LA Auto Show. To be entered to win a One-Day M School, please fill out the form below with your name, email address, phone number and ZIP code. 
                <br/><br/>
                The winner will be announced on Monday, December 16, 2024. Class will take place at the BMW Performance Center West, 86-050 Jasper Lane, Thermal, CA 92274.
              </p>
              <Formik
              initialValues={{ fullname: "", email: "", phone: "", zipcode: "" }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              >
                {({ errors, touched, handleSubmit, isSubmitting }) => (
                  <form onSubmit={handleSubmit}>
                    <div className="laas-form">
                      <div className="input__group">
                      <label htmlFor="fullname">*Fullname</label>
                        <Field
                          type="text"
                          name="fullname"
                          className={touched.fullname && errors.fullname ? 'has-error' : null}
                        />
                        {errors.fullname && touched.fullname && (
                          <span className="input-feedback">
                            {errors.fullname}
                          </span>
                        )}
                      </div>
                      <div className="input__group">
                        <label htmlFor="email">*Email</label>
                        <Field
                          type="email"
                          name="email"
                          className={touched.email && errors.email ? 'has-error' : null}
                        />
                        {errors.email && touched.email && (
                          <span className="input-feedback">{errors.email}</span>
                        )}
                      </div>
                      <div className="input__group">
                        <label htmlFor="phone">*Phone</label>
                        <Field
                          type="tel"
                          name="phone"
                          id="phone"
                          className={touched.phone && errors.phone ? 'has-error' : null}
                        />
                        {errors.phone && touched.phone ? <span className="input-feedback">{errors.phone}</span> : <span className="input-feedback">&nbsp;</span>}
                      </div>
                      <div className="input__group">
                        <label htmlFor="zipcode">*Zip Code</label>
                        <Field
                          type="text"
                          name="zipcode"
                          id="zipcode"
                          className={touched.zipcode && errors.zipcode ? 'has-error' : null}
                        />
                        {errors.zipcode &&
                        touched.zipcode ? <span className="input-feedback">{errors.zipcode}</span> : <span className="input-feedback">&nbsp;</span>}
                      </div>
                    </div>
                    <ReCAPTCHA sitekey={settings.REACT_APP_SITE_KEY} ref={captchaRef} onChange={val => setCaptchaToken(val)} onExpired={e => setCaptchaToken("")}/>
                    <br/>
                    <button
                      type="submit"
                      className="btn btn-blue"
                      disabled={isSubmitting}
                    >
                      {isLoading ? <Spinner /> : "Register"}
                    </button>
                  </form>
                )}
              </Formik>
              </div>
            </div>
            {getOfficialRules()}
          </div>
        </div>
        )}
      </div>
    </Layout>
  );
};

export default LAASContest;
